import { createRouter, createWebHistory } from 'vue-router';
import StartPage from '@/components/StartPage.vue';
import NotFound from '@/components/NotFound.vue';

const routes = [
    {
        path: '/',
        component: StartPage
    },
    {
        path: '/:pathMatch(.*)*', // 잘못된 경로는 404 페이지로 연결
        component: NotFound
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
